<template>
  <div id="widget-container" :class="{ 'd-none': $route.path !== '/paid-services' }">
    <!-- Фиксированная круглая кнопка -->
    <div class="floating">
      <button
          class="floating-action-button my-awesome-button text-circe-14"
          data-widget="appointment-widget"
      >Записаться к врачу
      </button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "WidgetComponent",
  };
</script>

<style scoped>
.d-none {
  display: none;
}
/* Стили для фиксированной круглой кнопки */
.floating-action-button {
  position: fixed;
  bottom: 40px;
  right: 40px;
  padding: 10px 20px;
  background-color: #66cc66;
  color: white;
  border: none;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  z-index: 1000;
  transition: all 0.3s ease;
}

.floating-action-button:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.3);
}

.floating-action-button:active {
  transform: scale(0.95);
}
</style>
