<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__row general-info__align-items">
        <DynamicMenu :menuItems="menuItems" />
        <div class="general-info__column">
          <div class="general-info__title"><h1 class="text-TTDrugs-Bold-40 color-green  text-uppercase">Отделение катамнестического наблюдения за детьми с перинатальной патологией</h1></div>
          <div class="general-info__index-mg">
            <AppSwiperDepartments :list_image="slides"/>
          </div>
        </div>
      </div>
      <div class="under-department-content">
        <div class="under-department-content__first-block">
          <h2>Кабинет катамнеза</h2>
          <p> <span class="bold">Кабинет катамнеза </span> организован для оказания высококвалифицированной специализированной медицинской помощи глубоко недоношенным детям, родившимся с очень низкой и экстремально низкой массой тела, а также детям, перенесшим критические состояния в периоде новорожденности, родившимся от матерей с сахарным диабетом (в том числе гестационным) и требующим динамического наблюдения.</p>
          <p>Команда специалистов кабинета катамнеза – компетентные и внимательные детские врачи различных специальностей, работа которых основана на коллегиальном подходе к решению каждой медицинской задачи отдельно взятого ребенка. Так, детей до одного года, наблюдают:</p>
          <ul class="under-department-content__list">
            <li>педиатры;</li>
            <li>неврологи;</li>
            <li>офтальмологи;</li>
            <li>хирурги.</li>
          </ul>
        </div>
        <div class="under-department-content__second-block">
          <div class="under-department-content__second-block-image">
            <img src="../../assets/img/departments/catamnesis/catamnesis1.jpg" alt="Катамнез" class="under-department-content__image" />
          </div>
          <div class="under-department-content__second-block-text">
            <p>Деятельность специалистов кабинета катамнеза направлена<span class="bold"> на сохранение и восстановление здоровья детей, рожденных с различными заболеваниями и патологией.</span> Тщательному наблюдению в нашем кабинете подлежат малыши:</p>
            <ul class="under-department-content__list">
              <li>родившиеся в тяжелом состоянии, с оценкой по школе Апгар 0-4 балла;</li>
              <li>перенесшие в неонатальном периоде состояние гипоксии, ацидоза, гипогликемии и другие критические состояния;</li>
              <li>перенесшие внутриутробную или неонатальную инфекцию;</li>
            </ul>
            <p class="bold">Врачи проводят плановый осмотр пациента и оценивают:</p>
            <ul class="under-department-content__list">
              <li>предъявляемые родителями жалобы в отношении ребенка;</li>
              <li>объективное состояние ребенка;</li>
              <li>антропометрические данные;</li>
              <li>нервно-психическое развитие ребенка;</li>
              <li>данные общеклинических и инструментальных методов исследований, представленных из медицинской организации по месту жительства;</li>
              <li>результаты осмотров врачей-специалистов;</li>
              <li>по показаниям проводят дополнительные исследования;</li>
              <li>определяют тактику ведения, в том числе индивидуальный график дальнейшего наблюдения, дают рекомендации по проведению реабилитационных мероприятий.</li>
            </ul>
            <p class="bold">В нашем центре Вы можете получить платные услуги без направления:</p>
            <ul class="under-department-content__list">
              <li>ЭХО-КГ;</li>
              <li>УЗИ внутренних органов и НСС, УЗИ тазобедренных суставов;</li>
              <li>Справки о состоянии здоровья (по итогу выздоровления, для посещения спортивных секций, выход в детское дошкольное учреждение после отпуска или пропуска);</li>
              <li>Консультация невролога для детских дошкольных учреждений и школы;</li>
              <li>ОАК, БАК, ОАМ;</li>
              <li>Получение онлайн консультаций при отсутствии возможности посещения доктора.</li>
            </ul>
            <p class="bold">Мы стремимся достичь главной цели – чтобы каждый малыш рос максимально здоровым и гармонично развивающимся человеком!</p>

          </div>
        </div>
      </div>
      <div class="general-info-department">

        <div class="general-info-department__info-block">
          <div class="general-info-department__title-left">
            <p class="text-TTDrugs-Bold-40 color-green center text-uppercase">Специалисты</p>
          </div>
        </div>
        <div class="general-info-department__thank">
          <BlockThankYou/>
        </div>
      </div>
      <AppDoctorsPeopleList :people_list="doctors"/>

    </div>
  </div>

</template>
<script>
  import DynamicMenu from '@/components/DynamicMenu.vue'
  import BlockThankYou from '@/components/BlockThankYou.vue'
  import AppSwiperDepartments from '@/components/Gallery/SwiperDepartments.vue'
  import { actionTypesDepartment } from '@/store/modules/department'
  import { mapState } from 'vuex'
  import AppDoctorsPeopleList from '@/components/People/DoctorsPeopleList.vue'

  export default {
    name: "AppCatamnesis",
    components: { AppDoctorsPeopleList, AppSwiperDepartments, BlockThankYou,   DynamicMenu },
    metaInfo() {
      return {
        title: 'Отделение катамнестического наблюдения | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Отделения Алтайского краевого клинического перинатального центра ДАР.Отделение катамнестического наблюдения.Подготовьтесь к родам вместе с Алтайским краевым клиническим перинатальным центром ДАР.'
          },
          {
            name: 'keywords',
            content: 'роды, сопровождение родов, родильный дом, партнерские роды, послеродовой уход, перинатальный центр ДАР'
          }
        ]
      };
    },
    data() {
      return {
        nameDepartment: 'Отделение катамнестического наблюдения',
        linksDepartment: 'kabinet-katamnesticheskogo-nablyudeniya-za-detmi-s-perinatalnoj-patologiej',
        menuItems: [
          { text: "Отделения", link: "/department", active: false },
          { text: "Поликлиника", link: "/department/policlinika", active: false },
          { text: "Акушерский стационар", link: "/department/akusherskij-stacionar", active: false },
          { text: "Неонатологический стационар", link: "/department/neonatologicheskij-stacionar", active: false },
          { text: "Центр гинекологии, восстановительной медицины и реабилитации", link: "/department/centr-ginekologii", active: false },
          { text: "Лабораторные и инструментальные методы обследования", link: "/department/laboratornye-i-instrumentalnye-metody-obsledovaniya", active: false },
          { text: "Отделение катамнестического наблюдения за детьми с перинатальной патологией", link: "/department/kabinet-katamnesticheskogo-nablyudeniya-za-detmi-s-perinatalnoj-patologiej", active: true },
          { text: "Центр медико-социальной поддержки женщин, оказавшихся в трудной жизненной ситуации", link: "/department/support-center", active: false },
          { text: "Вспомогательные службы", link: "/department/vspomogatelnye-sluzhby", active: false },
        ],
        slides: [
          { id: 1, image: require('../../assets/img/departments/catamnesis/catamnesis.jpg') },
          { id: 2, image: require('../../assets/img/departments/catamnesis/catamnesis1.jpg') },
          { id: 3, image: require('../../assets/img/departments/catamnesis/catamnesis2.jpg') },
          { id: 4, image: require('../../assets/img/departments/catamnesis/catamnesis3.jpg') },

        ]
      }
    },
    computed: {
      ...mapState({
        underDepartments: (state) => state.department.underDepartments,
        selectedDepartmentId: (state) => state.department.selectedDepartmentId,
        doctors: (state) => state.department.doctors
      }),
    },
    methods: {
      getDoctorsDepartment() {
        this.$store.dispatch(actionTypesDepartment.getDoctorsDepartment, this.nameDepartment)
            .then(() => {
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      }
    },
    mounted() {
      this.getDoctorsDepartment();

    }
  };
</script>

