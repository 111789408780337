<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__row general-info__align-items">
        <DynamicMenu :menuItems="menuItems" />
        <div class="general-info__column">
          <div class="general-info__title"><h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">Центр охраны здоровья семьи и репродукции</h1></div>
          <div class="general-info-department__description">
            <p class="text-circe-20 color-gray">Если вы оказались на этой странице, то, возможно, вы уже планируете беременность и прошли множество обследований, чтобы разобраться в причинах ее отсутствия. Возможно вы прошли ряд оперативных вмешательств и получили заключение от репродуктолога. А может быть у вас уже было несколько безрезультатных протоколов ЭКО, но вы не отчаиваетесь и ищете новые пути для обретения долгожданного счастья материнства.
            </p>
          </div>
        </div>
      </div>
      <div class="general-info-department">
        <div class="general-info-department__info-block">
          <div class="general-info-department__title-left">
            <p class="text-TTDrugs-Bold-40 color-green center text-uppercase">ЭКО</p>
          </div>
          <div class="general-info-department__description">
            <p class="text-circe-20 color-gray">
              Высококвалифицированные репродуктологи перинатального центра готовы помочь. Мы стремимся получить максимально возможный результат с сохранением физического и психологического комфорта для пациента. Специалисты подберут индивидуальную схему протокола ЭКО, который обязательно приведет к победе.
            </p>
          </div>
        </div>
<!--        <div class="general-info-department__thank">-->
<!--          <BlockThankYou/>-->
<!--        </div>-->
      </div>
      <div class="general-info-department">
      <section class="statistics-section4" >
        <div class="statistics-section4__wrap-index-block">
          <div class="statistics-section4__index-block-white">
            <div class="statistics-section4__img">
              <img src="../../assets/img/eco/eco1.jpg" alt="ЭКО 1">
            </div>
            <div class="statistics-section4__wrap-info-text" >
              <div class="statistics-section4__title">
                <p class="text-TTDrugs-Bold-40 color-green">ДИАГНОСТИКА ПРИЧИН БЕСПЛОДИЯ</p>
              </div>
              <div class="statistics-section4__flex">
                <div class="statistics-section4__text">
                  <p class="text-circe-20">Комплексное обследование при бесплодии у женщин обычно включает УЗИ, эхогистеросальпингоскопию, анализы на инфекции, биохимию и содержание гормонов, иногда — дополнительные исследования (гистерорезектоскопия, диагностическая лапароскопия и др.)</p>
                </div>
              </div>
              <div class="statistics-section4__btn" @click="routeDiagnostics">
                <button class="index-btn">Подробнее</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
      <div class="general-info-department">
      <section class="statistics-section3">
        <div class="">
          <div class="statistics-section3__wrap-index-block-white">
            <div class="statistics-section3__index-block">
              <div class="statistics-section3__title">
                <p class="text-TTDrugs-Bold-40 color-green">СТАТЬ ДОНОРОМ</p>
              </div>
              <div class="statistics-section3__wrap-info-text">
                <div class="statistics-section3__flex">
                  <div class="statistics-section3__text">
                    <p class="text-circe-20">Донорские программы в нашем центре – гарантия абсолютной медицинской и юридической безопасности для каждой семьи. Будущие родители могут самостоятельно выбрать доноров либо рекомендовать. Мы проводим все необходимые обследования состояния здоровья доноров</p>
                  </div>
                  <div class="statistics-section3__btn" @click="routeDonation">
                    <button class="index-btn">Подробнее</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="statistics-section3__img">
              <img src="../../assets/img/eco/eco2.jpg" alt="Секция 4">
            </div>
          </div>
        </div>
      </section>
      </div>
      <div class="general-info-department">
      <section class="statistics-section4" >
        <div class="statistics-section4__wrap-index-block">
          <div class="statistics-section4__index-block-white">
            <div class="statistics-section4__img">
              <img src="../../assets/img/eco/eco3.jpg" alt="ЭКО 3">
            </div>
            <div class="statistics-section4__wrap-info-text" >
              <div class="statistics-section4__title">
                <p class="text-TTDrugs-Bold-40 color-green">ЛЕЧЕНИЕ БЕСПЛОДИЯ</p>
              </div>
              <div class="statistics-section4__flex">
                <div class="statistics-section4__text">
                  <p class="text-circe-20">Наши программы - это комплекс лечебно-диагностических процедур, направленных на борьбу с мужским и женским бесплодием. Квалифицированные репродуктологи подберут индивидуальную схему протокола ЭКО, который обязательно станет победным</p>
                </div>
              </div>
              <div class="statistics-section4__btn" @click="routeInfertilityTreatment">
                <button class="index-btn">Подробнее</button>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
      <div class="general-info-department">
      <section class="statistics-section3">
        <div class="">
          <div class="statistics-section3__wrap-index-block-white">
            <div class="statistics-section3__index-block">
              <div class="statistics-section3__title">
                <p class="text-TTDrugs-Bold-40 color-green">СУРРОГАТНОЕ МАТЕРИНСТВО</p>
              </div>
              <div class="statistics-section3__wrap-info-text">
                <div class="statistics-section3__flex">
                  <div class="statistics-section3__text">
                    <p class="text-circe-20">У нашего центра большой опыт проведения программ ЭКО с привлечением суррогатных мам. Это индивидуальное комплексное решение, которое создается персонально для вас после консультации с лечащим врачом-репродуктологом.<br>
                      Программа суррогатного материнства используется в тех случаях, когда женщина по каким-либо причинам не способна выносить или родить ребенка.</p>
                  </div>
                  <div class="statistics-section3__btn" @click="routeSurrogacy">
                    <button class="index-btn">Подробнее</button>
                  </div>
                </div>
              </div>
            </div>
            <div class="statistics-section3__img">
              <img src="../../assets/img/eco/eco4.jpg" alt="Суррогатное материнство">
            </div>
          </div>
        </div>
      </section>
      </div>
      <div class="under-department-content">
        <div class="under-department-content__first-block-info">
          <div class="under-department-content__first-block-50">
            <p class="text-circe-bold-18"><span class="bold">Что получают наши пациенты:</span></p>

            <ul class="under-department-content__list">
              <li style="margin: 20px 0px;">Медицинское обслуживание у специалистов с большим опытом и высоким процентом положительных результатов.</li>
              <li  style="margin: 20px 0px;">Возможность использования оборудования, материалов и оригинальных лекарственных препаратов от ведущих мировых производителей.</li>
              <li  style="margin: 20px 0px;">Комплексный подход к решению каждого индивидуального случая, психологическую поддержку.</li>
              <li  style="margin: 20px 0px;">Удобный доступ ко всем необходимым медицинским консультациям и манипуляциям («всё в режиме единого окна»).</li>
              <li  style="margin: 20px 0px;">Возможность проведения медицинских манипуляций в любой удобный день.</li>
              <li  style="margin: 20px 0px;">Консультационную и медицинскую поддержку.</li>
            </ul>
          </div>
          <div class="under-department-content__second-block-50">
            <img src="../../assets/img/top-10.jpg" alt="top10">
          </div>
        </div>

      </div>
      <div class="general-info__index-mg">
        <AppSwiperDepartments :list_image="slides"/>
      </div>
      <div class="general-info-department">

        <div class="general-info-department__info-block">
          <div class="general-info-department__title-left">
            <p class="text-TTDrugs-Bold-40 color-green center text-uppercase">Специалисты</p>
          </div>
        </div>
        <div class="general-info-department__thank">
          <BlockThankYou/>
        </div>
      </div>
      <AppDoctorsPeopleList :people_list="doctors"/>
    </div>
  </div>

</template>
<script>
  import DynamicMenu from '@/components/DynamicMenu.vue'
  import BlockThankYou from '@/components/BlockThankYou.vue'
  import AppSwiperDepartments from '@/components/Gallery/SwiperDepartments.vue'
  import { mapState } from 'vuex'
  import AppDoctorsPeopleList from '@/components/People/DoctorsPeopleList.vue'
  import { actionTypesDepartment } from '@/store/modules/department'

  export default {
    name: "AppECO",
    components: { AppDoctorsPeopleList, AppSwiperDepartments, BlockThankYou,   DynamicMenu },
    metaInfo() {
      return {
        title: 'ЭКО | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Узнайте все об услугах экстракорпорального оплодотворения (ЭКО) в Алтайском краевом клиническом перинатальном центре ДАР. Мы предлагаем современные методы лечения бесплодия с использованием передовых технологий. Доверьте ваше здоровье нашим опытным специалистам.'
          },
          {
            name: 'keywords',
            content: 'эко, экстракорпоральное оплодотворение, методы лечения,  Алтайский краевой клинический перинатальный центр'
          }
        ]
      };
    },
    data() {
      return {
        nameDepartment: 'ЭКО',
        linksDepartment: 'family-center',
        menuItems: [
          { text: "ЭКО", link: "/family-center", active: true },
          { text: "Роды", link: "/childbirth", active: false },
          { text: "Плод и новорожденный", link: "/newborn", active: false },
          { text: "Беременность", link: "/pregnancy", active: false },
        ],
        slides: [
          { id: 1, image: require('../../assets/img/departments/eco/eco.jpg') },
          { id: 2, image: require('../../assets/img/departments/eco/eco1.jpg') },
          { id: 3, image: require('../../assets/img/departments/eco/eco2.jpg') },
          { id: 4, image: require('../../assets/img/departments/eco/eco3.jpg') },
          { id: 5, image: require('../../assets/img/departments/eco/eco4.jpg') },
          { id: 6, image: require('../../assets/img/departments/eco/eco5.jpg') },
          { id: 7, image: require('../../assets/img/departments/eco/eco6.jpg') },
          { id: 8, image: require('../../assets/img/departments/eco/eco7.jpg') },

        ]
      }
    },
    computed: {
      ...mapState({
        selectedDepartmentId: (state) => state.department.selectedDepartmentId,
        doctors: (state) => state.department.doctors
      }),
    },
    methods: {
      routeDiagnostics() {
        this.$router.push({ name: 'Diagnostics', path: '/family-center' + encodeURI('Диагностика причин бесплодия') });
      },
      routeDonation() {
        this.$router.push({ name: 'Donation', path: '/family-center' + encodeURI('Стать донором') });
      },
      routeInfertilityTreatment() {
        this.$router.push({ name: 'InfertilityTreatment', path: '/family-center' + encodeURI('Лечение бесплодия') });
      },
      routeSurrogacy() {
        this.$router.push({ name: 'Surrogacy', path: '/family-center' + encodeURI('Суррогатное материнство') });
      },
      getDoctorsDepartment() {
        this.$store.dispatch(actionTypesDepartment.getDoctorsDepartment, this.nameDepartment)
            .then(() => {
            })
            .catch((er) => {
              console.log("Ошибка", er);
            });
      }
    },
    mounted() {
      this.getDoctorsDepartment()
    }
  };
</script>

