<template>
  <div class="general-info">
    <div class="general-info__container">
      <div class="general-info__row general-info__align-items">
        <DynamicMenu :menuItems="menuItems" />
        <div class="general-info__column">
          <div class="general-info__title">
            <h1 class="text-TTDrugs-Bold-40 color-green center text-uppercase">Услуги ОМС</h1>
          </div>
          <div class="general-info__row">
            <div class="">
            <h2>Медицинская помощь по системе ОМС</h2>

            <p>В перинатальном центре «ДАР» имеется возможность получить медицинскую помощь по системе обязательного медицинского страхования (ОМС). Для этого необходимо при обращении предъявить полис ОМС - документ, удостоверяющий право застрахованного лица на бесплатное оказание медицинской помощи.</p>

            <p>Документ выдается гражданину бесплатно. Оплату оказанной пациенту медицинской помощи производит страховая компания, выдавшая полис ОМС.</p>

            <h3>В Алтайском крае обязательное медицинское страхование осуществляют следующие страховые организации:</h3>
            <ul class="under-department-content__list">
              <li>Алтайский филиал ООО «СМК РЕСО-Мед», г. Барнаул, ул. Мало-Олонская, 17. Телефон: 8 (800) 200-92-04</li>
              <li>Административное Структурное Подразделение ООО "Капитал МС" - Филиал в Алтайском крае, г. Барнаул, пр-т. Строителей, 4б.; ул. Георгиева, 57. Телефон: 8 (800) 100-81-02</li>
            </ul>

            <h3>При нарушении прав на получение бесплатной медицинской помощи можно обратиться:</h3>
            <ul class="under-department-content__list">
              <li>администрация медицинской организации;</li>
              <li>органы управления здравоохранением;</li>
              <li>страховая медицинская организация, выдавшая полис.</li>
            </ul>
            </div>
          </div>
        </div>
      </div>
<!--      <div class="under-department-content">-->
<!--        <div class="under-department-content__first-block-info">-->
<!--          <div class="under-department-content__first-block-50">-->
<!--            <h3>Граждане также имеют возможность получить медицинскую помощь в соответствии с Программой обязательного медицинского страхования, в том числе в части:</h3>-->
<!--            <br>-->
<!--            <br>-->
<!--            <br>-->
<!--            <ul class="under-department-content__list">-->
<!--              <li>прохождения профилактических медицинских осмотров и диспансеризации, в том числе углубленной диспансеризации для оценки репродуктивного здоровья женщин и мужчин с указанием перечня медицинских организаций;</li>-->
<!--              <li>прав на оказание медицинской помощи ветеранам боевых действий во внеочередном порядке.</li>-->
<!--            </ul>-->
<!--          </div>-->
<!--          <div class="under-department-content__second-block-50">-->
<!--            <img src="../../assets/img/oms.jpg" alt="oms">-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <div class="general-info-under-department">
      <div class="blog-page-preview">
        <div class="blog-page-preview__title">
          <p class="text-TTDrugs-Bold-40 color-green">ВМП ПО ОМС
          </p>
        </div>
        <div class="blog-page-preview__subtitle">
          <p class="text-circe-bold-24 color-gray">Высокотехнологичная медицинская помощь (далее - ВМП) – услуги, оказываемые с помощью инновационных технологий в лечении тяжелых заболеваний. К ним относятся лечебные и диагностические процедуры, выполняемые в специализированном стационаре.</p>
        </div>
        <div class="blog-page-preview__text">

            <h2>Высокотехнологичная медицинская помощь (ВМП) по ОМС</h2>

            <p>В перинатальном центре «ДАР» есть возможность получения ВМП по ОМС. Финансовое обеспечение в данном случае производится за счет субвенции из бюджета ФФОМС бюджетам ТФОМС.</p>

            <h3>Перечень основных видов ВМП по профилю «Акушерство и гинекология»:</h3>
            <ul class="under-department-content__list">
              <li>Комплексное лечение при привычном невынашивании беременности.</li>
              <li>Комплексное лечение плацентарной недостаточности.</li>
              <li>Комплексное лечение преэклампсии на сроке до 34 недели беременности с применением химиотерапевтических и биологических препаратов, эфферентных методов терапии.</li>
              <li>Экстракорпоральное оплодотворение.</li>
              <li>Комплексное лечение с применением фетальной хирургии.</li>
              <li>Хирургическое органосохраняющее лечение при гигантских опухолях гениталий.</li>
              <li>Хирургическое органосохраняющее лечение при миоме матки и опухолевых заболеваниях яичников.</li>
              <li>Хирургическое органосохраняющее лечение при пороках развития и аномалиях расположения гениталий и мочевыделительной системы.</li>
              <li>Хирургическое органосохраняющее лечение при несостоятельности мышц тазового дна, при стрессовом недержании мочи.</li>
              <li>Комплексная терапия при задержке полового созревания.</li>
              <li>Неинвазивное и малоинвазивное хирургическое органосохраняющее лечение при миоме матки.</li>
              <li>Хирургические лечение распространенного эндометриоза и т.д.</li>
            </ul>

            <p>ВМП по ОМС предоставляется любому гражданину страны, имеющему выявленные лечащим врачом показания. Пакет необходимых документов готовится лечебным учреждением, установившим факт заболевания. В пакет входит:</p>
            <ul class="under-department-content__list">
              <li>паспорт, СНИЛС, полис ОМС;</li>
              <li>результаты обследований, эпикризы;</li>
              <li>код диагноза (по МКБ) и вида медицинской помощи.</li>
            </ul>

            <p>Документы передаются в медицинскую организацию для предоставления ВМП или региональный профильный орган.</p>
        </div>
      </div>
      </div>
      <div class="general-info-under-department">
      <div class="blog-page-preview">
        <div class="blog-page-preview__title">
          <p class="text-TTDrugs-Bold-40 color-green">ЭКО ПО ОМС
          </p>
        </div>
        <div class="blog-page-preview__text">
          <h2>Что входит/не входит в программу ЭКО по ОМС?</h2>

          <h3>Базовая программа ЭКО со стимуляцией по ОМС</h3>
          <ul class="under-department-content__list">
            <li>первичная консультация врача-репродуктолога;</li>
            <li>контролируемая овариальная стимуляция (включая все необходимые лекарственные средства);</li>
            <li>УЗ-мониторинг фолликулогенеза и состояния эндометрия (повторные визиты к репродуктологу);</li>
            <li>трансвагинальная пункция фолликулов, осуществляемая под общим внутривенным обезболиванием;</li>
            <li>обработка эякулята супруга/партнера;</li>
            <li>оплодотворение ооцитов методом ЭКО и/или ИКСИ в зависимости от показаний;</li>
            <li>культивирование эмбрионов до 5-х суток развития;</li>
            <li>перенос эмбрионов в полость матки под контролем УЗ-исследования;</li>
            <li>криоконсервация эмбрионов.</li>
          </ul>

          <h3>Что не входит в рамки осуществления ЭКО по ОМС (оплачивается отдельно)</h3>
          <ul class="under-department-content__list">
            <li>хранение криоконсервированных эмбрионов;</li>
            <li>хэтчинг эмбрионов;</li>
            <li>предимплантационная генетическая диагностика (ПГД/ПГС) для выявления хромосомных аномалий и моногенных заболеваний;</li>
            <li>использование донорской спермы;</li>
            <li>использование донорских ооцитов;</li>
            <li>программы суррогатного материнства;</li>
            <li>необходимые дополнительные обследования;</li>
            <li>препараты, назначаемые с целью поддержания беременности позднее 14-го дня после осуществления переноса эмбриона.</li>
          </ul>

          <h3>Как попасть на ЭКО по ОМС?</h3>

          <h3>Получить в женской консультации по месту жительства выписку с диагнозом "Бесплодие"</h3>
          <ul class="under-department-content__list">
            <li>обратиться в женскую консультацию или к лечащему врачу (гинекологу) по месту жительства;</li>
            <li>врач проводит необходимые обследования с целью:
              <ul>
                <li>подтверждения диагноза бесплодия;</li>
                <li>выявления наличия или отсутствия противопоказаний к проведению программы ЭКО и вынашиванию беременности;</li>
              </ul>
            </li>
            <li>выдать выписку с диагнозом, который свидетельствует о бесплодии;</li>
            <li>если планируется проведение программы «Перенос криоконсервированного эмбриона» в рамках ОМС, необходимо предоставить выписку из протокола стимуляции и эмбриопротокола о наличии криоконсервированных эмбрионов.</li>
          </ul>

          <h3>Обследование должны пройти оба партнера</h3>
          <p>На комиссию должны предоставлены следующие документы:</p>
          <ul class="under-department-content__list">
            <li>два заявления (от каждого партнера);</li>
            <li>согласие на обработку персональных данных (от каждого партнера);</li>
            <li>ксерокопии паспортов;</li>
            <li>копия полиса ОМС будущей мамы;</li>
            <li>копия СНИЛС будущей мамы;</li>
            <li>выписка из женской консультации (должна включать все пройденные обследования с результатами).</li>
          </ul>

          <h3>Кто может воспользоваться программой ЭКО по ОМС?</h3>
          <ul class="under-department-content__list">
            <li>семейные пары с официально зарегистрированным браком;</li>
            <li>пары, живущие в гражданском браке (при условии, что партнеры не состоят в официальном браке с третьим лицом);</li>
            <li>одинокие женщины.</li>
          </ul>
          <p>Единственные условия - это Российское гражданство, наличие действующего полиса ОМС и подтвержденный диагноз «бесплодие».</p>

          <p class="bold">Важно знать!</p>
          <p>Пациентки старше 43 лет, имеющие низкий овариальный резерв (АМГ меньше 1), должны быть информированы о крайне низкой эффективности программы ЭКО со своими ооцитами и целесообразности применения донорских половых клеток.</p>
        </div>
      </div>
      </div>
    </div>
  </div>

</template>
<script>
  import DynamicMenu from '@/components/DynamicMenu.vue'
  export default {
    name: "AppServicesOMS",
    components: {  DynamicMenu },
    metaInfo() {
      return {
        title: 'Услуги ОМС | Алтайский краевой клинический перинатальный центр ДАР',
        meta: [
          {
            name: 'description',
            content: 'Подробная информация о Алтайском краевом клиническом перинатальном центре ДАР'
          },
          {
            name: 'keywords',
            content: 'пациентам, алтайский краевой клинический перинатальный центр, перинатальный центр, дар'
          }
        ]
      };
    },
    data() {
      return {
        nameDepartment: 'Услуги ОМС',
        linksDepartment: 'oms',
        menuItems: [
          { text: "Услуги ОМС", link: "/oms", active: true },
          { text: "Правила записи на прием", link: "/rules", active: false },
          { text: "Анализы", link: "/tests", active: false },
          { text: "Госпитализация", link: "/hospitalization", active: false },
          { text: "Иностранным гражданам", link: "/foreigners", active: false },
          { text: "Справка для налогового вычета", link: "/tax-refund", active: false },
          { text: "График прима граждан", link: "/schedule", active: false },
          { text: "Список продуктов для передачи", link: "/list-product", active: false },
          { text: "Молитвенная комната", link: "/prayer-room", active: false },
          { text: "Медицинский туризм", link: "/medical-tourism", active: false }
        ],

      }
    },

  };
</script>

