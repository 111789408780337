import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/scss/style.scss";
import LottieAnimation from 'lottie-web-vue';
import Vuelidate from 'vuelidate';
import VueMeta from 'vue-meta';
import WidgetComponent from "./components/WidgetComponent.vue";
Vue.component("WidgetComponent", WidgetComponent);
Vue.use(VueMeta);

Vue.use(Vuelidate);
import { truncate } from './helpers/filters.js';

import VueLazyLoad from "vue-lazyload";

Vue.use(VueLazyLoad);

Vue.use(LottieAnimation); // add lottie-animation to your global scope
Vue.config.productionTip = false;
Vue.filter('truncate', truncate);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
