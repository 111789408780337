<template>
  <header id="open" class="header">
    <div id="close" class="header__container">
      <div class="header__menu menu">
        <div class="menu__info menu-info">
            <div class="menu__social">
              <a href="https://ok.ru/perinat" target="_blank">
                <img src="../assets/svg/odnoklassniki.svg" alt="Одноклассники">
              </a>
              <a href="https://t.me/DARBARNAUL" target="_blank">
                <img src="../assets/svg/telegram.svg" alt="Телеграм">
              </a>
              <a href="https://vk.com/dar_altay_russia" target="_blank">
                <img src="../assets/svg/vk-dark.svg" alt="ВКонтакте">
              </a>
            </div>

          <div class="menu-info__index-info header-info">
            <div class="header-info__svg">
              <img src="../assets/svg/location.svg" alt="Локация" class="header-info__img">
            </div>
            <div class="header-info__wrap-text">
              <p class="text-circe-18 color-green">Алтайский край</p>
              <p class="text-circe-14 color-gray">г.Барнаул, ул. Фомина, 154</p>
            </div>
          </div>
          <router-link to="/" class="header__logo">
            <img src="../assets/svg/logo.svg" alt="Логотип" />
          </router-link>
          <div class="menu-info__index-info header-info">
            <div class="header-info__svg">
              <img src="../assets/svg/phone.svg" alt="Телефон" class="header-info__img-2">
            </div>
            <div class="header-info__wrap-text">
              <a href="tel:591130" class="text-circe-18 color-green">591-130</a>
              <p class="text-circe-14 color-gray">Телефоны горячей линии</p>
            </div>
          </div>
          <div class="burger-menu" @click="toggleMenu">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div class="menu-info__btn">
            <a href="https://medangel.xn--22-6kcp5d.xn--p1ai" target="_blank">
              <button class="login-btn" >
                <img src="../assets/svg/profile.svg" alt="Профиль">
                Личный кабинет
              </button>
            </a>
          </div>
        </div>
        <div class="menu__wrap">
          <nav class="menu__body" :class="{ 'menu__body--open': isOpen }">
            <ul class="menu__list">
              <li class="menu__item">
                <router-link to="/" class="text-TTDrugs-regular-16 text-uppercase" @click.native="toggleMenu">Главная</router-link>
              </li>
              <li class="menu__item--dropdown">
                <router-link to="/about" class="text-TTDrugs-regular-16 text-uppercase" @click.native="toggleMenu">О центре</router-link>
                <ul class="dropdown-menu">
                  <router-link to="/about/blog" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Блог главного врача</li></router-link>
                  <router-link to="/about/management" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Руководство</li></router-link>
                  <router-link to="/about/specialists" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Специалисты</li></router-link>
                  <router-link to="/about/general-info" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Общие сведения</li></router-link>
                  <router-link to="/documents" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Документы</li></router-link>
                  <router-link to="/about/schedule" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Режим работы</li></router-link>
                  <router-link to="/about/contacts" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Контакты</li></router-link>
                  <router-link to="/about/vacancies" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Вакансии</li></router-link>
                  <router-link to="/about/news" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Новости</li></router-link>
                  <router-link to="/about/reviews" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Отзывы</li></router-link>
                  <router-link to="/about/gallery" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item"  :class="[{'no-border': true}]">Галерея</li></router-link>
                </ul>
              </li>
              <li class="menu__item--dropdown">
                <router-link to="/department" class="text-TTDrugs-regular-16 text-uppercase" @click.native="toggleMenu">Отделения</router-link>
                <ul class="dropdown-menu">
                  <router-link to="/department/policlinika" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Поликлиника</li></router-link>
                  <router-link to="/family-center" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Центр охраны здоровья семьи и репродукции</li></router-link>
                  <router-link to="/department/akusherskij-stacionar" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Акушерский стационар</li></router-link>
                  <router-link to="/department/neonatologicheskij-stacionar" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Неонатологический стационар</li></router-link>
                  <router-link to="/department/centr-ginekologii" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Центр гинекологии, восстановительной медицины и реабилитации</li></router-link>
                  <router-link to="/department/laboratornye-i-instrumentalnye-metody-obsledovaniya" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Лабораторные и инструментальные методы обследования</li></router-link>
                  <router-link to="/department/kabinet-katamnesticheskogo-nablyudeniya-za-detmi-s-perinatalnoj-patologiej" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Отделение катамнестического наблюдения за детьми с перинатальной патологией</li></router-link>
                  <router-link to="/department/support-center" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Центр медико-социальной поддержки женщин, оказавшихся в трудной жизненной ситуации</li></router-link>
                  <router-link to="/department/vspomogatelnye-sluzhby" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item" :class="[{'no-border': true}]">Вспомогательные службы</li></router-link>
                </ul>
              </li>
              <li class="menu__item--dropdown">
                <router-link to="/services" class="text-TTDrugs-regular-16 text-uppercase" @click.native="toggleMenu">Услуги</router-link>
                <ul class="dropdown-menu">
                  <router-link to="/family-center" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">ЭКО</li></router-link>
                  <router-link to="/childbirth" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Роды</li></router-link>
                  <router-link to="/newborn" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Плод и новорожденный</li></router-link>
                  <router-link to="/pregnancy" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item" :class="[{'no-border': true}]">Беременность</li></router-link>
                </ul>
              </li>
              <li class="menu__item--dropdown">
                <router-link to="/patients" class="text-TTDrugs-regular-16 text-uppercase" @click.native="toggleMenu">Пациентам</router-link>
                <ul class="dropdown-menu">
                  <router-link to="/oms" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Услуги ОМС</li></router-link>
                  <router-link to="/rules" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Правила записи на прием</li></router-link>
                  <router-link to="/tests" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Анализы</li></router-link>
                  <router-link to="/hospitalization" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Госпитализация</li></router-link>
                  <router-link to="/foreigners" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Иностранным гражданам</li></router-link>
                  <router-link to="/tax-refund" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Справка для налогового вычета</li></router-link>
                  <router-link to="/schedule" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">График прима граждан</li></router-link>
                  <router-link to="/list-product" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Список продуктов для передачи</li></router-link>
                  <router-link to="/prayer-room" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Молитвенная комната</li></router-link>
                  <router-link to="/medical-tourism" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item" :class="[{'no-border': true}]">Медицинский туризм</li></router-link>
                </ul>
              </li>
              <li class="menu__item">
                <router-link to="/paid-services" class="text-TTDrugs-regular-16 text-uppercase" @click.native="toggleMenu">Платные услуги</router-link>
              </li>
              <li class="menu__item">
                <router-link to="/education" class="text-TTDrugs-regular-16 text-uppercase" @click.native="toggleMenu">Образование</router-link>
<!--                <ul class="dropdown-menu">-->
<!--                  <router-link to="/normative-documents" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Нормативные документы</li></router-link>-->
<!--                  <router-link to="/plans-programs" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Планы и программы</li></router-link>-->
<!--                  <router-link to="/attestation" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Аттестация</li></router-link>-->
<!--                  <router-link to="/enrollment-documents" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Документы для зачисления на курсы</li></router-link>-->
<!--                  <router-link to="/school-of-parents" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Школа будущих родителей</li></router-link>-->
<!--                  <router-link to="/volunteer-activities" class="text-TTDrugs-regular-16" @click.native="toggleMenu"><li class="dropdown-item">Волонтерская деятельность</li></router-link>-->
<!--                </ul>-->
              </li>
              <li class="menu__item">
                <router-link to="/appeal" class="text-TTDrugs-regular-16 text-uppercase">Написать обращение</router-link>
              </li>
            </ul>
          </nav>
          <div class="menu-contact__button">
            <div class="menu-contact__button-svg-search" @click="toggleSearch">
              <img src="../assets/svg/search.svg" alt="Поиск">
            </div>
            <div class="menu-contact__button-svg-eye" @click="$emit('toggleAccessibility')">
              <img id="specialButton" style="cursor:pointer;" src="https://lidrekon.ru/images/special.png" alt="ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ" title="ВЕРСИЯ ДЛЯ СЛАБОВИДЯЩИХ" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <AppSearch v-if="showSearch"  @close-search="closeSearch" :showSearch="showSearch"  ref="searchArea"/>
<!--    <transition name="slide-fade">-->
<!--      <div v-if="showSearch" class="search-bar">-->
<!--        <input type="text" placeholder="Введите для поиска..." class="search-input text-TTDrugs-bold-18 color-white">-->
<!--      </div>-->
<!--    </transition>-->
  </header>
</template>

<script>
  import AppSearch from '@/components/Search/Search.vue'

  export default {
    name: "AppHeader",
    components: { AppSearch },
    data() {
      return {
        showSearch: false,
        isOpen: false
      };
    },
    methods: {
      toggleSearch() {
        this.showSearch = !this.showSearch;
      },
      closeSearch() {
        this.showSearch = false;
      },
      handleClickOutside(event) {
        const searchArea = this.$refs.searchArea?.$el;
        const searchIcon = this.$refs.searchIcon;

        if (
            this.showSearch &&
            searchArea &&
            !searchArea.contains(event.target) &&
            !searchIcon.contains(event.target)
        ) {
          this.closeSearch();
        }
      },
      toggleMenu() {
        this.isOpen = !this.isOpen;
      },
      routerGo() {
        if (this.$route.path !== '/appeal') {
          this.$router.push('/appeal');
        }
      }
    },
    mounted() {
      document.addEventListener("click", this.handleClickOutside);
    },
    beforeDestroy() {
      document.removeEventListener("click", this.handleClickOutside);
    }
  };
</script>

